import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Linking } from "react-native";
export interface GetResponse {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        url: string;
        price: number;
        description: string;
        created_at: string;
        image: string;
      };
    }
  ];
}
export interface Item {
  id: string;
  type: string;
  attributes: {
    url: string;
    price: number;
    description: string;
    created_at: string;
    image: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  idUrl: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  list: Item[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idAffiliate: string | number;
  // Customizable Area End
}

export default class AffiliateUrlController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetUrlKey: string | Message = "";
  apiCallIdPostCount: string | Message = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      list: [],
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiCallIdGetUrlKey !== null &&
      this.apiCallIdGetUrlKey ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        this.setState({ loading: false, list: responseJson.data });
      } else {
        this.setState({ loading: false });
      }
      this.parseApiCatchErrorResponse(errorResponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiCallIdPostCount !== null &&
      this.apiCallIdPostCount ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJsonPost = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponsePost = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJsonPost) {
        this.setState({ loading: false });
        return true;
      } else {
        this.setState({ loading: false });
      }
      this.parseApiCatchErrorResponse(errorResponsePost);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getUrl();
  }
  getUrl = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetUrlKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postCountFunc = async (idLink: string) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdPostCount = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPointPost + idLink
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePressImage = (urlLink: string, idLink: string) => {
    this.postCountFunc(idLink);
    Linking.openURL(urlLink);
  };

  // Customizable Area End
}
