Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.description='description : '
exports.price='price : '
exports.rupee='$'
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_affiliate_url/affiliate_urls";
exports.title='Trending list';
exports.exampleAPiEndPointPost = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_affiliate_url/affiliate_urls/click_count?id=";
// Customizable Area End
