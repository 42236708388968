Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = `https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai//bx_block_context_tagging/context_taggings?location_type=`;
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";
exports.reportHeader="Report";
exports.reportTitle= "Why you are reporting this review";
exports.reportSubTitle="Your reporting is anonymous. We won't share your report unless you report it as intellectual proprty infrigement";
exports.labelName ="Name of the Business";
exports.labelAddress="Full address business";
exports.labelContact="Contact number /Mobile /Landline"
exports.labelTiming="Timings";
exports.labelTimingStart="Timing Start";
exports.labelTimingEnd="Timing End";
exports.labelTo="to"
exports.labelWebsite="Website";
exports.labelCategory="Category";
exports.labelAbout="About the business /crypto ATM";
exports.labelSelect="Select the location on Map";
exports.labelAddimg="Add Image";
exports.labelCamera="Camera";
exports.labelAttach="Attach Files"
exports.labelSubmit="Submit"
exports.btnExampleTitle = "CLICK ME";
exports.labelOpens="Open - Close 12 PM";
exports.labelDirection="Directions"
exports.labelCall="Call"
exports.Constants={
  LOCATION_DATA:[]
}
exports.Regex = /^[0-9\b]+$/
exports.addLocationUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_context_tagging/context_taggings"
exports.alertMessage='location added successfully'
// Customizable Area End