import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  idvideo: string;
  navigation:any
  // Customizable Area Start

  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isReady: boolean,
  quality: number,
  token: string
  // Customizable Area End
}

interface SS {
    // Customizable Area Start
  idvideo: string | number;
    // Customizable Area End
}

export default class VideoPlayController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start

      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      isReady: false,
      quality: 0,
      token: ""
      // Customizable Area End

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
 
  
  // Customizable Area End
}