//@ts-nocheck
import React, { Fragment } from "react";
// Customizable Area Start
import GoogleMapReact from "google-map-react";
import RoomIcon from "@material-ui/icons/Room";
import PlacesAutocomplete from "react-places-autocomplete";
export interface CategoryType {
  ides: number;
  title: string;
  isSelected: boolean;
}

const CategoryData = [
  {
    ides: 0,
    title: "Crypto ATM",
    isSelected: false,
  },
  {
    ides: 1,
    title: "Cash ATM",
    isSelected: false,
  },
  {
    ides: 2,
    title: "NFT Galleries",
    isSelected: false,
  },
  {
    ides: 3,
    title: "Businessess",
    isSelected: false,
  },
];

const AnyReactComponent = () => (
  <div>
    <RoomIcon color="error" style={{ fontSize: "50px" }} />
  </div>
);

import AddNewLocationController, {
  configJSON,
  Images,
  Props,
} from "./AddNewLocationController";
import {
  Box,
  Container,
  Grid,
  ThemeProvider,
  createTheme,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
export default class AddNewLocation extends AddNewLocationController {
  mapRef: React.RefObject<unknown>;
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.mapRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  renderImage = (image: Images, index: number) => {
    return (
      <Fragment key={`unique-img-${index}`}>
        <img
          style={Styles.ImgTagStyle}
          src={URL.createObjectURL(image)}
          alt="mainImg"
        />
      </Fragment>
    );
  };
  // Customizable Area End

  render() {
    //  Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Grid
            container
            spacing={4}
            style={{ marginTop: "25px" }}
            id="main_box"
          >
            <Grid item xs={12}>
              <TextField
                id="businessname"
                label={configJSON.labelName}
                variant="outlined"
                fullWidth={true}
                value={this.state.name}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.txtInputBusinessNamePrpos(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={Styles.place}>
                <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.onChangeAddress}
                  onSelect={this.handleSelect}
                  data-testid="location"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                      style={{width:'100%',height:'55px',fontSize:16}}
                        {...getInputProps({
                          placeholder: "Add Location",
                        })}
                      />
                      <div
                        style={{
                          overflowY: "auto",
                          maxHeight: "100px",
                          width: "100%",
                          cursor: "pointer",
                          marginBottom: "5px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              style={{
                                overflowWrap: "break-word",
                                fontSize: "15px",
                                padding: "3px",
                              }}
                            >
                              {suggestion.description}{" "}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="contactno"
                label={configJSON.labelContact}
                variant="outlined"
                fullWidth={true}
                value={this.state.contact}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.txtPhoneNumberProps(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="timing1"
                label={configJSON.labelTimingStart}
                variant="outlined"
                type="time"
                defaultValue="07:30"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.txtInputTime1Prpos(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="timing2"
                label={configJSON.labelTimingEnd}
                variant="outlined"
                type="time"
                defaultValue="07:30"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.txtInputTime2Prpos(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="websitetext"
                label={configJSON.labelWebsite}
                variant="outlined"
                fullWidth={true}
                value={this.state.website}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.txtInputWebsitePrpos(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{configJSON.labelCategory}</InputLabel>
                <Select
                  id="enableFilteBtn"
                  label="Filter"
                  fullWidth
                  onChange={(event: { target: { value: string } }) =>
                    this.onSelectCategoryWeb(event.target.value)
                  }
                >
                  {CategoryData.map((item: CategoryType, index: number) => {
                    return (
                      <MenuItem value={item.title} key={index}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="cryptotext"
                label={configJSON.labelAbout}
                variant="outlined"
                fullWidth={true}
                multiline
                minRows={4}
                value={this.state.aboutus}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.txtInputAboutPrpos(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} style={{ height: "300px" }}>
              <GoogleMapReact
                defaultCenter={{
                  lat: 59.95,
                  lng: 30.33,
                }}
                defaultZoom={11}
              >
                <AnyReactComponent lat={59.95} lng={30.33} text="My Marker" />
              </GoogleMapReact>
            </Grid>
            <Grid item xs={12}>
              {
                <Box my={4} display="flex">
                  {this.state.images.length > 0 &&
                    this.state.images.map((image, index) =>
                      this.renderImage(image, index)
                    )}
                </Box>
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="pickmultipleFun"
                label={configJSON.labelAttach}
                variant="outlined"
                fullWidth={true}
                type="file"
                inputProps={{
                  multiple: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => this.pickMultipleWebImage(event.target.files)}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Button
                id="addloc"
                variant="contained"
                color="primary"
                style={{ width: "200px" }}
                onClick={() => this.addLocation()}
              >
                {configJSON.labelSubmit}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Styles = {
  ImgTagStyle: {
    width: "100px",
    height: "100px",
    padding: "10px",
  },
  place: {
    "& .css-26l3qy-menu": {
      zIndex: "999999",
    },
  },
};
// Customizable Area End
