 
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";

const FilterModelData = [
  {
    ides: 0,
    title: 'Crypto ATM',
    isSelected: false
  },
  {
    ides: 1,
    title: 'Cash ATM',
    isSelected: false
  },
  {
    ides: 2,
    title: 'NFT Gallaries',
    isSelected: false
  },
  {
    ides: 3,
    title: 'Bussinesses',
    isSelected: false
  },
]

 export interface ListItem {
  id: string,
  type: string,
  attributes: {
      latitude:number,
      longitude: number,
      name: string,
      address: string,
      contact_no: string,
      from: string,
      to: string,
      website: string,
      about: string,
      status?: null|string|undefined,
      location_type: string,
      likes?:boolean,
      images: [
          {
              id: number,
              url: string,
              type: string,
              filename: string
          }
      ]
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  idis: string | number;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  locationList: ListItem[];
  loading: boolean;
  flatlistIndex: number;
  filterModelData: {
    isSelected: boolean;
    ides: number,
    title: string
  }[],
  filterExpand: boolean;
  showCard: boolean;
  currentLocation: { lat: number,
    lng: number};
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idis: string | number;
  // Customizable Area End
}

export default class ContextTaggingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  locationList: string | Message = "";
  mapRef: object;
  flatListRef: object;
  labelOpens: string;
  labelDirection: string;
  labelCall: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];
    this.state = {
      index: 0,
      locationList: [],
      loading: false,
      flatlistIndex: 0,
      filterModelData: FilterModelData,
      filterExpand: false,
      showCard: false,
      currentLocation: {  
        lat:0,
        lng: 0}
    };
    this.labelOpens = configJSON.labelOpens;
    this.labelDirection = configJSON.labelDirection;
    this.labelCall = configJSON.labelCall
    this.mapRef = React.createRef();
    this.flatListRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.locationList !== null &&
      this.locationList ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        this.setState({
          currentLocation: {  
            lat: responseJson.data[0].attributes.latitude,
            lng: responseJson.data[0].attributes.longitude},
            locationList: responseJson.data, 
            loading: false, 
            showCard: true
        })
      } 
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End
  }

  // Customizable Area Start

 async componentDidMount() {
    this.newLocation()
  }

  newLocation =()=>{
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        currentLocation: {  
          lat: position.coords.latitude,
          lng: position.coords.longitude}
      })
    });
  }

  _currentLocation = () => {
    window.location.replace('/AddNewLocation')
  }

  _handleFilterCategoryPress = (data: string) => {
    this.getLocationList(data)
  }

  handleDirection = (mapsDir:ListItem)=>{

    if(mapsDir){
    this.setState({
      currentLocation: {  
        lat: mapsDir.attributes.latitude,
        lng: mapsDir.attributes.longitude}
    })
  }
  }

  getLocationList = (title: string) => {
    this.setState({ loading: true })
    const header = {
      'Content-Type': 'application/json',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.locationList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint+title
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
