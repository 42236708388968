//@ts-nocheck
import React from "react";

 // Customizable Area Start
 import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
  // Customizable Area End
import AffiliateUrlController, { Props } from "./AffiliateUrlController";

interface Item {
    id: string;
    type: string;
    attributes: {
      url: string;
      price: number | null;
      description: string;
      created_at: string;
      image: string;
    };
}

export default class AffiliateUrl extends AffiliateUrlController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderList = () => {
    return (
      <>
         <Grid item container spacing={4} xs={12} direction="row" alignItems="center">
        {this.state.list.map((item: Item, index) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Card
                id={"cardPress"}
             onClick={()=>this.handlePressImage(item.attributes.url, item.id)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="365"
                    image={item.attributes.image}
                    style={{ objectFit: "contain" }}
                  />
                  <CardContent>
                    <Typography variant="body2" component="p">
                      {item.attributes.description}
                    </Typography>
                </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      </>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        id="main_box"
      >
        <Grid
            container
            item
            spacing={4}
            xs={12}
            direction="row"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
        {/* Customizable Area Start */}
        {/* Merge Engine UI Engine Code */}
        {
           this.state.loading ? 
            <CircularProgress /> :this.renderList()
             
        }
        {/* Merge Engine UI Engine Code */}
        {/* Customizable Area End */}
        </Grid>
     </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
