import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require("react-navigation")
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  route:{
    params:{
     item :{
        attributes: {
          availability: number;
          breadth: number;
          description: string;
          discount: string;
          height: number;
          images: [
            {
              id: number;
              url: string;
              type: string;
              filename: string;
            }
          ];
          length: number;
          manufacture_date: string;
          name: string;
          on_sale: true;
          price: number;
          recommended: false;
          sale_price: string;
          sku: string;
          stock_qty: number;
          weight: string;
        };
        id: string;
        type: string;
      }
    }
  }
  navigation:  typeof navigation;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  item: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string|number;
  // Customizable Area End
}

export default class TrendingProductDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  imageUrl = this.props.navigation.state?.params.item;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
      // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      item: localStorage.getItem('item') ? JSON.parse(localStorage.getItem('item') as string):''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
