Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.deleteLabel = "delete";
exports.savedCard="Saved Cards"
exports.name="Name :"
exports.noData="No Data Found"
exports.creditCardLabel = "creditcard";
exports.delete = "Delete";
exports.save = "Save";
exports.addNewCard = "+ Add New Card";
exports.addNew = "Add New Card";
exports.close = "close";
exports.holderName = "Card Holder Name";
exports.enterHolderName = "Enter CardHolder Name";
exports.cardNumber = "Debit/Credit Card Number";
exports.enterCardNumber = "Enter card Number";
exports.expDate = "Exp. Date";
exports.enterExpDate = "Enter Exp. Date";
exports.cvv = "CVV";
exports.enterCvv = "Enter CVV Number";
exports.saveCardButton = "Save Card";
exports.keyBoardType = "number-pad";
exports.cardAddedPopup = "Card is Added Successfully";
exports.exampleApiContentType = "application/json";
exports.cardListUrl =
  "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_save_card/save_cards";
exports.deleteCardUrl =
  "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_save_card/save_cards/";
exports.getMethod = "GET";
exports.deleteMethod = "DELETE";
exports.postMethod = "POST";
exports.validationsMessage="Please Enter all Card Details"

// Customizable Area End
