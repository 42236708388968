//@ts-nocheck
import React from "react";

// Customizable Area Start
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import TrendingProductDetailsController, { Props,configJSON } from "./TrendingProductDetailsController.web";

// Customizable Area End

export default class TrendingProductDetails extends TrendingProductDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
       <Box id="main_block"  display="flex" flexWrap='wrap' justifyContent="start"
       alignItems="center">
           <Grid container  spacing={4} xs={12} direction="row"
           justifyContent="start"
           alignItems="center"
           style={{marginTop: '20px'}}
           >
                <Grid item xs={12} md={6} lg={4}>
           {  <Card >
              <CardActionArea>
                <CardMedia
                testID={'image_key'}
                  component="img"
                  alt="Contemplative Reptile"
                  height="140"
                  image={this.state.item?.attributes?.images[0]?.url}
                  title="Contemplative Reptile"
                />
                <CardContent>
            
                  <Typography variant="body2" component="p">
                  {configJSON.description}
                  </Typography>

                  <Typography    testID={'description_key'}  variant="body2"  component="p">
                  {this.state.item?.attributes?.description}
                  </Typography>
                  <Typography variant="body2" component="p">
                  {configJSON.price}
                  </Typography>
                  <Typography testID={'price_key'}  variant="body2" component="p">
                  {this.state.item?.attributes?.price}
                  </Typography>
                  <Typography variant="body2" component="p">
                  {configJSON.discount}
                  </Typography>
                  <Typography testID={'discount_key'}  variant="body2" component="p">
                  {configJSON.demoDetailsData?.discount}
                  </Typography>
                  <Typography variant="body2" component="p">
                  {configJSON.availbleQuantity}
                  </Typography>
                  <Typography testID={'stock_qty_key'}  variant="body2" component="p">
                  {this.state.item?.attributes?.stock_qty}
                  </Typography>
                </CardContent>
              </CardActionArea>
            
            </Card>}
            </Grid>
            </Grid>
            </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
