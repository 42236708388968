//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Select,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Dimensions } from "react-native";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@material-ui/icons/Room";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import DirectionsIcon from "@material-ui/icons/Directions";
import CallIcon from "@material-ui/icons/Call";
import BusinessIcon from "@material-ui/icons/Business";

interface ListItem {
  id: string;
  type: string;
  attributes: {
    latitude: number;
    longitude: number;
    name: string;
    address: string;
    contact_no: string;
    from: string;
    to: string;
    website: string;
    about: string;
    status?: null | string | undefined;
    location_type: string;
    likes?: boolean;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}

export interface Item {
  id: string;
  type: string;
  attributes: {
    latitude: number;
    longitude: number;
    name: string;
    address: string;
    contact_no: number;
    from: string;
    to: string;
    website: string;
    about: string;
    status: null;
    location_type: string;
    images: null;
  };
}
const AnyReactComponent = () => (
  <div>
    <RoomIcon color="error" style={{ fontSize: "50px" }} />
  </div>
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ContextTaggingController, {
  Props,
  configJSON,
} from "./ContextTaggingController.web";

export default class ContextTagging extends ContextTaggingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderItem = (item:ListItem,index:number) => {
    return (
      <Card
      id="location_list_id"
      key={index}
      style={{ margin: "15px 15px",width:'300px' }}
    >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item.attributes?.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {item.attributes?.location_type} -{" "}
            {item.attributes?.address}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {this.labelOpens}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          id="directionBtn"
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<DirectionsIcon />}
          onClick={() => this.handleDirection(item)}
        >
          {this.labelDirection}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<CallIcon />}
        >
          {this.labelCall}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<BusinessIcon />}
        ></Button>
      </CardActions>
    </Card>
    );
  };

  absoluteView = () => {
    return (
      <>
        <Button
          id="currentLocBtn"
          variant="contained"
          style={webStyle.addButton}
          startIcon={<AddLocationIcon />}
          onClick={() => this._currentLocation()}
        >
          Add Location
        </Button>
        <Box sx={webStyle.filterButton}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Filter
            </InputLabel>
            <Select
              id="enableFilteBtn"
              label="Filter"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this._handleFilterCategoryPress(event.target.value)
              }
            >
              {this.state.filterModelData?.map((item, index: number) => {
                return (
                  <MenuItem key={index} value={item.title}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container id="main_box">
      <Box style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact center={this.state.currentLocation} defaultZoom={11}>
          {this.state.locationList.length > 0 ? (
            this.state.locationList?.map(
              (marker: ListItem, index: number) => {
                return (
                  <AnyReactComponent
                    key={index}
                    lat={marker.attributes.latitude}
                    lng={marker.attributes.longitude}
                    text="My Marker"
                  />
                );
              }
            )
          ) : (
            <AnyReactComponent
              lat={this.state.currentLocation.lat}
              lng={this.state.currentLocation.lng}
              text="My Marker"
            />
          )}
        </GoogleMapReact>
        {this.absoluteView()}
        {this.state.showCard && 
           <Grid container>
           <Box sx={webStyle.sliderStyle}>
               {this.state.locationList?.map((item, index) => this.renderItem(item,index))}
           </Box>
         </Grid>
        }
      </Box>
    </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  addButton: {
    position: "absolute",
    right: "80px",
    top: "11px",
    background: "#fff",
    height: "40px",
  },
  filterButton: {
    position: "absolute",
    right: "265px",
    top: "11px",
    background: "#fff",
    width: "130px",
    "& .MuiOutlinedInput-input": {
      padding: "12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px)",
      color: "#000",
    },
  },
  sliderStyle: {
    position: "absolute",
    bottom: "30px",
    width: "100%",
    display:'flex',
    flexDirection:'row',
    overflow: 'auto'
  },
};
// Customizable Area End
