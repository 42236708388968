//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  Divider,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CreditCardIcon from '@material-ui/icons/CreditCard';

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SavedCardsController, {
  Props,
  configJSON,
} from "./SavedCardsController";

export default class SavedCards extends SavedCardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box display={'flex'} justifyContent="space-between" p={5} >
            <Typography variant="h5">{configJSON.savedCard} </Typography>
            <Button onClick={this.handleNewCardAdd} variant="contained" testID="addNewCard" color="primary">{configJSON.addNewCard} </Button>
          </Box>
          <Divider />
          <Grid container spacing={4}>
            {
              this.state.cardListData.length > 0 ? this.state.cardListData.map((item, index) => {
                return (
                  <Grid id="card_list" key={`card-list-${index}`} item md={6} xs={12}>
                    <Card  >
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          {configJSON.name + item.attributes.card_holder_name}
                        </Typography>

                        <Box display={'flex'} alignItems="center" justifyContent="space-between" flexDirection={'row'}>
                          <CreditCardIcon />
                          <Typography align="center" variant="body2" >
                            {item.attributes.card_number}
                          </Typography>
                          <Button variant="contained" onClick={() => this.handleDeleteCard(item.id)} id={"delete_button"} color="secondary"> {configJSON.delete}</Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })
                : <div>{configJSON.noData} </div>}

          </Grid>
        </Container>

        <Dialog id="card_model" maxWidth="sm" fullWidth open={this.state.showAddCardModal}
          onClose={this.handleClose}>
          <DialogTitle>{configJSON.addNew}</DialogTitle>
          <Divider />
          <DialogContent>
            <Box my={2}>
              <InputLabel>
                {configJSON.holderName}
              </InputLabel>
              <Input
                testID="holder_name"
                type={"text"}
                fullWidth={true}
                value={this.state.name}
                onChange={(event) => this.onChangeName(event.target.value)}
              />
            </Box>


            <Box my={2}>
              <InputLabel>
                {configJSON.cardNumber}
              </InputLabel>
              <Input
                testID="card_number"
                fullWidth
                value={this.state.cardNumber}
                onChange={(event) => this.onChangeCardNumber(event.target.value)}
                type="number"
              />
            </Box>

            <Box my={2}>
              <InputLabel>
                {configJSON.expDate}
              </InputLabel>
              <Input
                testID="exp_date"
                fullWidth
                value={this.state.expDate}
                onChange={(event) => this.onChangeExpDate(event.target.value)}
                type="text"

              />
            </Box>

            <Box my={2}>
              <InputLabel>
                {configJSON.cvv}
              </InputLabel>
              <Input
                testID="cvv_no"
                fullWidth
                value={this.state.cvvNumber}
                onChange={(event) => this.onChangeCVV(event.target.value)}
                type="number"

              />
            </Box>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleClose} color="secondary">{configJSON.close} </Button>
            <Button variant="contained" onClick={this._handleSavedCard}
              testID="submit_button" color="primary">{configJSON.save} </Button>
          </DialogActions>
        </Dialog>

      </ThemeProvider>
      // Customizable Area End

    );
  }
}

// Customizable Area Start

// Customizable Area End
