import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert } from "react-native";
interface Item { 
  id: string,
  type: string,
  attributes: {
    id: number,
    card_holder_name: string,
    card_number: number,
    cvv_number: number,
    card_type: number|string,
  },
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  idSaveCard: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  cardNumber: string;
  expDate: string;
  cvvNumber: string;
  cardModify: boolean;
  currentIndex: number;
  cardListData: Item[];
  loading: boolean;
  refresh: boolean;
  showAddCardModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idCard: string | number;
  // Customizable Area End
}

export default class SavedCardsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  cardList: string | Message = "";
  addCard: string | Message = "";
  deleteCard: string | Message = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      name: "",
      cardNumber: "",
      expDate: "",
      cvvNumber: "",
      cardModify: false,
      currentIndex: 0,
      cardListData: [],
      loading: false,
      refresh: false,
      showAddCardModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.cardList !== null &&
      this.cardList ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ cardListData: responseJson.data.reverse(), loading: false });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addCard !== null &&
      this.addCard ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        this.setState({ loading: false, refresh: !this.state.refresh });
        alert(configJSON.cardAddedPopup);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteCard !== null &&
      this.deleteCard ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        this.setState({
            loading: false,
            refresh: !this.state.refresh,
            cardModify: false,
          });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getCardList();
  }

  async componentDidUpdate(prevProps: object, prevState: { refresh: boolean }) {
    if (prevState.refresh !== this.state.refresh) {
      this.getCardList();
    }
  }

  getCardList = async () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cardList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cardListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDeleteCard = async (idCard: string | number) => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCard = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCardUrl+idCard
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  postNewCard = async () => {
    this.setState({ loading: true });
    let cardObj = {
      card_holder_name: this.state.name,
      card_number: this.state.cardNumber.split(" ").join(""),
      cvv_number: this.state.cvvNumber,
      card_type: "1",
      expiry_date: this.state.expDate,
    };

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCard = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cardListUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cardObj)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  isStringNullOrBlank = (strr: string) => strr === null || strr.length === 0;

  handleNewCardAdd = () => this.setState({ showAddCardModal: true });

  handleClose = () => this.setState({ showAddCardModal: false });

  onChangeName = (texts: string) => this.setState({ name: texts });

  onChangeCardNumber = (texts: string) => this.setState({ cardNumber: texts });

  onChangeExpDate = (texts: string) => {
    if (texts.length == 2 && this.state.expDate.length == 1) texts += "/";
    else if (texts.length == 2 && this.state.expDate.length == 3)
      texts = texts.substring(0, texts.length - 1);
    this.setState({ expDate: texts });
  };

  onChangeCVV = (texts: string) => this.setState({ cvvNumber: texts });

  handleCardModify = (index: number) =>
    this.setState({ cardModify: true, currentIndex: index });

  _handleSavedCard = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.cardNumber) ||
      this.isStringNullOrBlank(this.state.expDate) ||
      this.isStringNullOrBlank(this.state.cvvNumber)
    ) {
      Alert.alert(configJSON.validationsMessage);
      return false;
    } else {
      this.postNewCard();
      this.handleClose();
    }
  };
  // Customizable Area End
}
