//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import React from "react";
import ImagePicker from "react-native-image-crop-picker";
const navigation = require("react-navigation")
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export interface CategoryDataTypes {
    idtitle: number,
    title: string,
    isSelected: boolean,
}

interface Images {
  height:number,
  uri: string,
  width:number
  mime?:string|number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  idiss: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  name: string;
  address: string ;
  aboutus: string;
  website: string;
  contact: string;
  data: string[];
  time1: string;
  time2: string;
  city: string;
  phone: string;
  loading: boolean;
  isImagePickerOptions: boolean;
  images: Images[];
  lat1: number;
  lng1: number;
  nameError: string;
  addressError: string;
  contactError: string;
  websiteError: string;
  aboutusError: string;
  imagesError: string;
  showCategory: boolean;
  category: string;
  categoryError: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  idiss: string | number;
  // Customizable Area End
}

export default class AddNewLocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdLocation: string | Message = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];


    this.state = {
      isImagePickerOptions: false,
      images: [],
      name: "",
      nameError: '',
      addressError: '',
      contactError: '',
      websiteError: '',
      aboutusError: '',
      imagesError: '',
      address: "",
      contact: "",
      aboutus: "",
      website: "",
      data: [],
      city: "",
      time1: "",
      time2: "",
      phone: "",
      loading: false,
      lat1: 22.764351,
      lng1: 5.891151,
      showCategory: false,
      category: '',
      categoryError: '',
    };



    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiCallIdLocation !== null &&
      this.apiCallIdLocation ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        this.setState({ loading: false });
        this.showAlert(configJSON.alertMessage)
        this.props.navigation.goBack()
      }else{
        this.setState({loading: false})
      }

      this.parseApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  pickMultiple() {
    ImagePicker.openPicker({
      multiple: true,
      waitAnimationEnd: false,
      sortOrder: "desc",
      includeExif: true,
      forceJpg: true,
    })
      ?.then(
        (images) => {
          images.map((iMapp) => {
            this.setState({
              images: [
                ...this.state.images,
                {
                  uri: iMapp.path,
                  width: iMapp.width,
                  height: iMapp.height,
                  mime: iMapp.mime,
                },
              ],
            });
          });
        })
      .catch(
        (error) => alert(error));
  }
  pickMultipleWebImage(files: Blob[]) {
    if(files){
    this.setState({
      images: [
        ...this.state.images,
        files[0]
      ],
    });}
  }

  takeImageFromCamera = () => {
    ImagePicker.openCamera({
      width: 300,
      height: 400,
      cropping: false,
      includeBase64: true,
    }).then((resMap) => {

      this.setState({ isImagePickerOptions: false });
      this.setState({
        images: [
          ...this.state.images,
          {
            uri: resMap.path,
            width: 300,
            height: 400,
          },
        ],
        loading: false,
      });
    });
  };
  
  async addLocation() {
    this.setState({loading:true})
    const header = {
    };
    const formData = new FormData();
    formData.append("name", this.state.name)
    formData.append("address", this.state.address)
    formData.append("contact_no", this.state.contact)
    formData.append("website", this.state.website);
    formData.append("about", this.state.aboutus)
    formData.append("latitude", this.state.lat1)
    formData.append("longitude", this.state.lng1)
    formData.append("location_type", this.state.category)
    formData.append("from", this.state.time1)
    formData.append("to", this.state.time2)

if(this.isPlatformWeb()){
  this.state.images.map((item: Images) => {
    formData.append("images[]", item)
  })
}else{
  this.state.images.map((item: Images) => {

    formData.append("images[]", {
      uri: item.uri,
      type: "image/png",
      name: "image"
    })
  })
}  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdLocation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addLocationUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  txtInputBusinessNamePrpos = (text: string) => {
    this.setState({ name: text, nameError: '' });
  };

  txtPhoneNumberProps = (text: string) => {
    this.setState({ contact: text, contactError: '' });
  };

  txtInputTime1Prpos = (text: string) => {
    this.setState({ time1: text });
  };

  txtInputTime2Prpos = (text: string) => {
    this.setState({ time2: text });
  };

  txtInputWebsitePrpos = (text: string) => {
    this.setState({ website: text, });
  };

  txtInputAboutPrpos = (text: string) => {
    this.setState({ aboutus: text, aboutusError: '' });
  };

  showCategory = () => this.setState({
    showCategory: !this.state.showCategory
  })

  onSelectCategory = (item: CategoryDataTypes) => {
    this.setState({ category:item.title, showCategory: false, categoryError: '' })
  }
  onSelectCategoryWeb = (item: CategoryDataTypes) => {
    this.setState({ category:item, })
  }

  getCoordsFromName( details:
    {
      geometry:{
        location:{
          lat:number,
          lng:number
        }
      },
      formatted_address:string
    }|null
    ) {
    this.setState({

      address: details?.formatted_address,
      lat1: details?.geometry.location.lat,
      lng1: details?.geometry.location.lng,
    });
  }

  handleSelect = async (value:object) => {
    try {
      const results = await geocodeByAddress(value);
      const { lat, lng } = await getLatLng(results[0]);
      this.setState({ address: value, lat1:lat,lng1:lng});
    } catch (error) {
    }
  };

  onChangeAddress =(text:string)=>this.setState({address:text})
  // Customizable Area End
}
