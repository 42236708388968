//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  Card,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactPlayer from "react-player"

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#dc004e",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


import VideoPlayController, {
    Props,
} from "./VideoPlayController";
export default class VideoPlay extends VideoPlayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
                <Box style={webStyle.mainWrapper}>
                    <Container maxWidth={"sm"} style={{ height: '100%', maxHeight: '600px' }}>
                        <Card  style={{borderRadius:'15px'}} id='videoPlay'>
                            <ReactPlayer
                                className='react-player'
                                url='https://www.youtube.com/watch?v=cLRztK1zE6s'
                                width='100%'
                                height='100%'
                                controls={true}
                            />
                        </Card>

                    </Container>
                </Box>
            </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        padding: "30px",
        background: "#d5cecef5",
        height: '100%'
    },
};
// Customizable Area End
